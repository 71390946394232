$(function () {

	var $svgLogo = $('.svg');
	if($svgLogo.length > 0) {
	    function useSvg() {
	        return document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
	    }

	    if ( ! useSvg() ) {
	        $svgLogo.prop('src', $svgLogo.data('fallback'));
	    }
	}

	var $window     = $(window);

	if($window.width() > 1180) {
	    var $top = $('.header .top');
	    var $el = $('nav.mainMenu');
	    var $scrollTop = $el.offset().top - $top.height();
	    var $isFixed = false;
	    $(document).on('scroll', function(e) {
	        var $this = $(this);
	        if($isFixed === false) {
	            if($this.scrollTop() >= $scrollTop) {
	                $el.addClass('fixed');
	                $isFixed = true;
	            }
	        } else {
	            if($this.scrollTop() < $scrollTop) {
	                $el.removeClass('fixed');
	                $isFixed = false;
	            }
	        }
	    }).trigger('scroll');
	}

    $('.randomCategoryProduct .figure, .randomCategoryProduct .randomCategory, .indexInfoImageBox').on('click', function(e) {
        $link = $('a:first', $(this));
        if ($link.length > 0) {
            window.location.href = $link.attr('href');
        }
    });

    var $treeMenu   = $('.treeMenu');
    if(RESPONSIVE) {
        var $slideBars      = new $.slidebars(); // Create new instance of Slidebars
        var $slidebarLeft   = $('.slidebarLeft');
        var $slidebarRight  = $('.slidebarRight');
        var $sbLeft         = $('.sb-left');
        var $sbRight        = $('.sb-right');

        //Open left slidebar
        if($sbLeft.length > 0) {
            $slidebarLeft.on('click', function(e) {
                e.preventDefault();
                $slideBars.slidebars.toggle('left');
            });
        }

        //Open right slidebar
        if($sbRight.length > 0) {
            $slidebarRight.on('click', function(e) {
                e.preventDefault();
                $slideBars.slidebars.toggle('right');
            });

            $sbRight.on('click', '.closeSidebar', function(e) {
                e.preventDefault();
                $slideBars.slidebars.close();
            });

            $sbRight.on('openCart', function() {
                $slideBars.slidebars.open('right');
            });
        }

        //Initialize fastclick to avoid the click delay on touchscreens.
        if($window.width() <= 1024) {
            FastClick.attach(document.body);
        }

        $('.toggleMenu', $treeMenu.parent()).on('click', function() {
            $(this).parent().toggleClass('open')
        });
    }

    // Sliders
    window_width = $(window).width();

    // Sliders on start page
    if($("#randomDiscountProductsSliderWrapper").length) {
        randomDiscountProducts = new Swiper ('#randomDiscountProductsSliderWrapper .swiper-container', {
            loop: true,
            speed: 1000,
            spaceBetween: 20,
            prevButton: $('#randomDiscountProductsSliderWrapper .previous_slide'),
            nextButton: $('#randomDiscountProductsSliderWrapper .next_slide'),
            slidesPerView: 4,
            slidesPerGroup: 4
            ,
            breakpoints: {
                900: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                },
                760: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                }
            }
        });
    }

    if($("#newProductsSliderWrapper").length) {
        newProducts_swiper = new Swiper ('#newProductsSliderWrapper .swiper-container', {
            loop: true,
            speed: 1000,
            spaceBetween: 20,
            prevButton: $('#newProductsSliderWrapper .previous_slide'),
            nextButton: $('#newProductsSliderWrapper .next_slide'),
            slidesPerView: 4,
            slidesPerGroup: 4
            ,
            breakpoints: {
                900: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                },
                760: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                }
            }
        });
    }

    if($("#relatedProductsSliderWrapper").length) {
        relatedProducts_swiper = new Swiper ('#relatedProductsSliderWrapper .swiper-container', {
            loop: true,
            speed: 1000,
            spaceBetween: 20,
            prevButton: $('#relatedProductsSliderWrapper .previous_slide'),
            nextButton: $('#relatedProductsSliderWrapper .next_slide'),
            slidesPerView: 4,
            slidesPerGroup: 4
            ,
            breakpoints: {
                900: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                },
                760: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                }
            }
        });
    }
});


